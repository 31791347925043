import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import {
  mobilePromo1,
  mobilePromo2,
  mobilePromo3,
  mobilePromo4,
  mobilePromo5,
  mobilePromo6,
  promotions,
} from 'images'
import Slider from 'react-slick'

const settings = {
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  pauseOnHover: false,
  draggable: true,
  swipe: true,
}
export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView w="100%">
          <Slider {...{ ...settings, slidesToShow: 1 }}>
            <CFImage
              src={mobilePromo1}
              style={{ objectFit: 'contain' }}
              ph="25px"
              pv="20px"
            />
            <CFImage
              src={mobilePromo2}
              style={{ objectFit: 'contain' }}
              ph="25px"
              pv="20px"
            />
            <CFImage
              src={mobilePromo3}
              style={{ objectFit: 'contain' }}
              ph="25px"
              pv="20px"
            />
            <CFImage
              src={mobilePromo4}
              style={{ objectFit: 'contain' }}
              ph="25px"
              pv="20px"
            />
            <CFImage
              src={mobilePromo5}
              style={{ objectFit: 'contain' }}
              ph="25px"
              pv="20px"
            />
            <CFImage
              src={mobilePromo6}
              style={{ objectFit: 'contain' }}
              ph="25px"
              pv="20px"
            />
          </Slider>
          <CFView
            h3
            w="100%"
            textCenter
            ph="5%"
            pb="20px"
            style={{ lineHeight: '30px' }}
          >
            Here at Avo House - Robson St., Vancouver you'll experience
            delicious Japanese cuisine. Try our mouth-watering dishes, carefully
            prepared with the freshest quality ingredients! At Avo House, our
            recipe for success is simple - great food & care makes customers
            return every time.
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1400px">
          <CFImage src={promotions} w="100%" alt="Promotions" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
